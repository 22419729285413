import { FC } from "react";
import styles from "./PropPab.module.sass";
import { Container } from "../../../../components/Container";
import classNames from "classnames/dedupe";
import BorderImage from "../../../../assets/img/border2.png";
import LeadImg from "../../../../assets/img/ui/lead.png";
import LogoPag from "../../../../assets/img/PabLogo.svg";
import Bg from "../../../../assets/img/third_bg.png";

export const ProPab: FC = () => {
  return (
    <div className={styles.rootBg}>
      <img className={styles.bg} src={Bg} alt="bg" />
      <Container className={classNames("df-start fd-column", styles.root)}>
        <div className={classNames("df-between", styles.quoteWrapper)}>
          <div
            data-aos="fade-down"
            className={classNames("df-center", styles.quote)}
          >
            <img className={styles.border} src={BorderImage} alt={"border"} />
            <p className={styles.quoteText}>
              Ми сприймаємо бізнес як синтез окремих елементів. Його успіх
              залежить від вдалого поєднання. Полтавська асоціація бізнесу
              створює простір для пошуку власної формули успіху для вашого
              бізнесу.
            </p>
          </div>
          <div className={styles.img}>
            <img
              className={classNames("img", styles.leadImg)}
              src={LeadImg}
              alt={"img: Pab Leader"}
            />
          </div>
        </div>
        <div className={classNames("df-between", styles.description)}>
          <h2 className={styles.title}>що ми вже робимо?</h2>
          <div className={classNames("fd-column", styles.section)}>
            <h6 className={styles.name}>Ірина Бессарабова</h6>
            <p className={styles.subname}>
              керівниця Полтавської асоціації бізнесу
            </p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={classNames("df-center", styles.card, styles.card1)}>
            <strong className={styles.strong}>750+</strong>
            <p className={styles.cardText}>годин спілкування</p>
          </div>
          <div
            className={classNames(
              "df-center",
              styles.card,
              styles.card2,
              styles.cardBlue,
            )}
          >
            <strong className={styles.strong}>500+</strong>
            <p className={styles.cardText}>бізнес-зустрічей</p>
          </div>
          <div className={classNames("df-center", styles.card, styles.card3)}>
            <strong className={styles.strong}>100+</strong>
            <p className={styles.cardText}>відкритих заходів</p>
          </div>
          <div className={styles.logo}>
            <img className="img" src={LogoPag} alt={"img: Logo Pag"} />
          </div>
          <div
            className={classNames(
              "df-center",
              styles.card,
              styles.card4,
              styles.cardRight,
            )}
          >
            <p className={styles.cardText}>
              Зовнішні проєкти для розвитку малого та середнього бізнесу
            </p>
          </div>
          <div
            className={classNames(
              "df-center",
              styles.card,
              styles.card5,
              styles.cardRight,
              styles.cardBlue,
            )}
          >
            <p className={styles.cardText}>
              Соціальні проєкти та інвестиції в молодь
            </p>
          </div>
          <div
            className={classNames(
              "df-center",
              styles.card,
              styles.card6,
              styles.cardRight,
            )}
          >
            <p className={styles.cardText}>
              Спілкування, витончене дозвілля та розвиток учасників
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};
