import { FC } from "react";
import { Container } from "../../../../components/Container";
import styles from "./Workshop.module.sass";

export const Workshop: FC = () => {
  return (
    <Container className={styles.root}>
      <h3 className={styles.title}>ДЕЯКІ З ВОРКШОПІВ, ЩО ЧЕКАЮТЬ НА ВАС:</h3>
      <div className={styles.content}>
        <div className={styles.card}>
          <div className={styles.header}>
            <h4>
              Віталій Дмитрієв{"\n"}
              <span>та</span> Ганна Приходченко
            </h4>
          </div>
          <p className={styles.text}>
            Людський капітал як найцінніший ресурс бізнесу. Як адаптуватися до
            нових реалій ринку праці та залишатися конкурентоспроможним у
            сучасних умовах.
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.header}>
            <h4>Олександр Колесніков</h4>
          </div>
          <p className={styles.text}>
            Майбутнє бізнесу: Автоматизація за допомогою AI
          </p>
        </div>
      </div>
    </Container>
  );
};
