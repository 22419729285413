import { FC, useCallback } from "react";
import { Container } from "../../../../components/Container";
import styles from "./Program.module.sass";
import Speaker1 from "../../../../assets/img/program/1.png";
import Speaker2 from "../../../../assets/img/program/2.png";
import Speaker3 from "../../../../assets/img/program/3.png";
import Speaker4 from "../../../../assets/img/program/4.png";
import Speaker5 from "../../../../assets/img/program/5.png";
import Speaker6 from "../../../../assets/img/program/6.png";
import Speaker7 from "../../../../assets/img/program/7.png";
import Speaker8 from "../../../../assets/img/program/8.png";
import Speaker9 from "../../../../assets/img/program/9.png";

interface IProgram {
  name: string;
  image: string;
  text: string;
}

const programData: IProgram[] = [
  {
    name: "Євген Глібовицький",
    text: "Тема узгоджується зі спікером",
    image: Speaker1,
  },
  {
    name: "Світлана Стреляна",
    text: "Демографічна криза.\nБрак людей чи брак компетенцій?",
    image: Speaker2,
  },
  {
    name: "Артур Міхно",
    text: "Тема узгоджується зі спікером",
    image: Speaker3,
  },
  {
    name: "Володимир Поперешнюк",
    text: "Як знаходити драйвер розвитку в кризах. ",
    image: Speaker4,
  },
  {
    name: "Єрік Найман",
    text: "Бізнес та економіка воєнного часу.\nТри сценарії розвитку економічного\nта соціального майбутнього України.",
    image: Speaker5,
  },
  {
    name: "Ігор Шейко",
    text: "Практичне використання ШІ. Зростання ефективності бізнесу\nна фоні демографічної кризи.",
    image: Speaker6,
  },
  {
    name: "Ігнатьєв Станіслав",
    text: "Стан енергетичної системи в Україні: НОВА ЕНЕРГЕТИЧНА КАРТА. Енергосталість бізнесу.",
    image: Speaker7,
  },
  {
    name: "Роман Гавриш",
    text: "Тема узгоджується зі спікером",
    image: Speaker8,
  },
  {
    name: "Михайло Пацан",
    text: "Інвестування та криптовалюти",
    image: Speaker9,
  },
];

export const Program: FC = () => {
  const getProgramCard = useCallback(
    (item: IProgram, index: number) => (
      <div data-aos="zoom-out" className={styles.card}>
        <div className={styles.wrapper}>
          <h3 className={styles.header}>{item.name}</h3>
          <p className={styles.text}>{item.text}</p>
        </div>
        <img className={styles.img} src={item.image} alt={`Speaker ${index}`} />
      </div>
    ),
    [],
  );

  return (
    <Container className={styles.root}>
      <h2 className={styles.title}>Програма форуму</h2>
      <div className={styles.content}>
        {programData.map((item, index) => getProgramCard(item, index))}
      </div>
    </Container>
  );
};
