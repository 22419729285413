// @ts-ignore
import Video from "../../../../assets/video/videoBg.mp4";
import Image from "../../../../assets/img/main_bg.jpg";
import { Container } from "../../../../components/Container";
import { Logo, LogoSize } from "../../../../components/Logo";
import UkrSibBank from "../../../../assets/img/partners/ukrSibBankWhite.svg";
import LogoPab from "../../../../assets/img/PabLogo.svg";
import { Button, ButtonType } from "../../../../components/Button";
import classNames from "classnames/dedupe";
import styles from "./Banner.module.sass";
import { useEffect } from "react";

export const Banner = () => {
  useEffect(() => {
    setTimeout(function () {
      // @ts-ignore
      document.querySelector("#video")?.play();
    }, 1000);
  });

  return (
    <div className={styles.root}>
      <div className={styles.bannerWrapper}>
        <img src={Image} className="img" alt="Main Banner" />
        {/*<video*/}
        {/*  id="video"*/}
        {/*  className="img"*/}
        {/*  poster={BannerImg}*/}
        {/*  autoPlay*/}
        {/*  loop*/}
        {/*  muted*/}
        {/*  playsInline={true}*/}
        {/*  preload={"none"}*/}
        {/*>*/}
        {/*  <source src={Video} />*/}
        {/*</video>*/}
      </div>

      <Container className={classNames("df-center", styles.container)}>
        <Logo className={styles.logo} size={LogoSize.medium} color={true} />
        <h1 className={styles.title} data-aos="fade-up">
          ПЕРШИЙ ПОЛТАВСЬКИЙ БІЗНЕС-ФОРУМ
        </h1>
        <div className="df-between">
          <p className={styles.subtitle}>29 ЛИСТОПАДА</p>
          <hr className={styles.line} />
          <p className={styles.subtitle}>ПОЛТАВА</p>
        </div>
        <div className={classNames("df-between", styles.info)}>
          <div
            className={classNames(
              "df-center fd-column",
              styles.infoBlock,
              styles.infoMain,
            )}
          >
            <p>Організатор</p>
            <img src={LogoPab} alt="logo: Pab" />
          </div>
          <div className={classNames("df-center", styles.mb2, styles.fullBtn)}>
            <Button
              size={ButtonType.Medium}
              className={classNames(styles.btn)}
            />
          </div>
          <div className={classNames("df-center fd-column", styles.infoBlock)}>
            <p>Головний партнер</p>
            <img src={UkrSibBank} alt="logo: UKRSIBBANK" />
          </div>
        </div>
      </Container>
    </div>
  );
};
